<template>
  <div style="background:#ffffff;padding:15px">
    <div class="title">
      {{ detail.title }}
    </div>
    <div class="author">
      <span> {{ detail.author }} </span>
      <span style="color: #a6a6a6"> {{ detail.created_at }} </span>
    </div>
    <img class="banner" :src="detail.banner" alt="" />
    <div class="content cke_editable cke_editable_themed cke_contents_ltr cke_show_borders" v-html="detail.content"></div>
    <div class="operation">
      <div class="btn" @click="_golink">
        <img
          v-show="detail.is_like"
          src="@/assets/article/tjf_fq_wzxq_like_pre_icon.png"
          alt=""
        />
        <img
          v-show="detail.is_like == false"
          src="@/assets/article/tjf_fq_wzxq_like_nor_icon.png"
          alt=""
        />
        <div class="btn_text">
          <span>赞</span>
          {{ detail.likes_count }}
        </div>
      </div>
      <div class="btn" @click="_golink">
        <img src="@/assets/article/tjf_fq_wzxq_share_icon.png" alt="" />
        <div class="btn_text">
          <span>分享</span>
          {{ detail.shares_count }}
        </div>
      </div>
      <div class="vertical"></div>
    </div>
    <van-overlay :show="show" @click="show = false">
      <self-mask></self-mask>
    </van-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import {
  isMicroMessenger,
  isAndroid,
  isIOS,
  isMobile,
} from "@/utils/user-agent";
import { Overlay } from "vant";
import './contents.css'
import { ArticleItem } from "@/api/article.js";
import SelfMask from "@/views/alipage/ToTaoBao";
Vue.use(Overlay);
export default {
  components: {
    SelfMask,
  },

  data() {
    return {
      detail: {},
      id: 0,
      show: false,
    };
  },

  created() {
    this.id = this.$route.params.id;
    this.ArticleItem();
  },

  methods: {
    //获取详情
    ArticleItem(id) {
      ArticleItem({
        id: this.id,
      }).then((res) => {
        if (res.errCode === 0) {
          this.detail = res.data;
          this.detail.created_at =
            this.detail.created_at.slice(5, 10).replace(/-/, "月") + "日";
        }
      });
    },

    //去下载
    _golink() {
      if (isIOS) {
        var ios_link =
          "https://apps.apple.com/cn/app/%E6%B7%98%E8%A1%97%E5%9D%8A/id1444751745";
        window.location.href = ios_link;
        return "ios";
      } else {
        // 安卓微信浏览器需要在默认浏览器打开
        // if (isMicroMessenger) {
        //   this.show = true;
        //   return false;
        // }

        var andriod_link =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        // "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        window.location.href = andriod_link;
        return "andriod";
      }
    },
  },
};
</script>

<style lang="less">
.title {
  font-size: 21px;
  font-weight: bold;
  color: #272727;
  line-height: 30px;
}
.author {
  margin: 12px 0 23px;
  display: flex;
  align-items: center;
  span {
    font-size: 15px;
    color: #fb295b;
    margin-right: 11px;
  }
}
.banner {
  margin: 0 5px 33px;
  width: 335px;
  height: 77px;
}
.content {
  padding: 0 5px 45px;
  img {
    margin: 10px 0;
    width: 100%;
  }
}

.operation {
  border-top: 1px solid #eaeaea;
  background-color: #ffffff;
  width: 100%;
  height: 50px;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  .btn {
    flex: 1;
    font-size: 15px;
    font-weight: 600;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
    img {
      margin-right: 8px;
      width: 25px;
      height: 25px;
    }
    &:first-child img {
      width: 27px;
      height: 24px;
    }
  }
  .vertical {
    position: absolute;
    width: 0.5px;
    left: 50%;
    top: 12px;
    transform: translateX(-50%);
    height: 25px;
    background: #c9c9c9;
  }
}
</style>